// src/components/Deletedata.tsx

import React from 'react';
import '../styles/DeleteData.scss';

const DeleteData: React.FC = () => {
  return (
    <div className="deletedata-page">
      <div className="deletedata-page_block">
        <h2>Data Deletion Request</h2>
        <p>This is User's data deletion request page.</p>
        <p>Game name: Dark Country</p>
        <p>Developer: Mykhaylovych Oleh</p>

        <div>In order to request deletion of all data please write us to <a href="mailto:contact@darkcountry.io">contact@darkcountry.io</a></div>
      </div>
    </div>
  );
};

export default DeleteData;
