// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/Hero';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteData from './components/Deletedata';


import { ReactComponent as Vihor } from './images/Vihr_dod.svg';
import { ReactComponent as LogoImg } from './images/SVG/logo.svg';
import { ReactComponent as GhostImg1 } from './images/SVG/ghost_1.svg';
import { ReactComponent as GhostImg2 } from './images/SVG/ghost_2.svg';

import './styles/App.scss';



const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <LogoImg className="logo" />

        <div className="centered-images">
          <Vihor className="vihor" />
        </div>

        <div className="ghosts">
          <GhostImg1 className="ghost-1" />
          <GhostImg2 className="ghost-2" />
        </div>

        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/deletedata" element={<DeleteData />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
